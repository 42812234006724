import { defaultLocale, localeOptions } from '../../constants/config';

import {
    CHANGE_LOCALE,
    SET_SETTINGS,
    SET_DEFAULT_LOGO,
    SET_IP_WHITELIST,
    SET_RESTRICTION,
    SET_VERSION,
    SET_GPDR_DAYS,
    SET_GPDR,
    SET_GDPR_SETTINGS
} from '../actions';

const INIT_STATE = {
    locale:
        localStorage.getItem('currentLanguage') &&
        localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
            ? localStorage.getItem('currentLanguage')
            : defaultLocale,
    platformSettings: {},
    platformLogo: '',
    allowedIPs: [],
    version: [],
    gdprSettings: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return { ...state, locale: action.payload };
        case SET_SETTINGS:
            return { ...state, platformSettings: action.payload };
        case SET_DEFAULT_LOGO:
            return { ...state, platformLogo: action.payload };
        case SET_IP_WHITELIST:
            return { ...state, allowedIPs: action.payload };
        case SET_RESTRICTION:
            return {
                ...state,
                platformSettings: {
                    ...state.platformSettings,
                    ipRestriction: action.payload
                }
            };
        case SET_VERSION:
            return { ...state, version: { ...action.payload } };
        case SET_GPDR_DAYS:
            return {
                ...state,
                gdprSettings: {
                    ...state.gdprSettings,
                    gdprExpiryDays: action.payload
                }
            };
        case SET_GPDR:
            return {
                ...state,
                gdprSettings: {
                    ...state.gdprSettings,
                    gdprEnable: action.payload
                }
            };
        case SET_GDPR_SETTINGS:
            return { ...state, gdprSettings: { ...action.payload } };
        default:
            return { ...state };
    }
};
