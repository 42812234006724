import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    LOGGED_USER
} from '../actions';

export const loginUser = (user, history, messages) => ({
    type: LOGIN_USER,
    payload: {user, history, messages}
});

export const loginUserSuccess = user => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});
export const loginUserError = message => ({
    type: LOGIN_USER_ERROR,
    payload: {description: message}
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: {user, history}
});
export const registerUserSuccess = user => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});
export const registerUserError = message => ({
    type: REGISTER_USER_ERROR,
    payload: {message}
});

export const logoutUser = history => ({
    type: LOGOUT_USER,
    payload: {history}
});

export const loggedUserData = user => ({
    type: LOGGED_USER,
    payload: user
});