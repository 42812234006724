import {
    SET_ACTIVE_TAB,
    SET_JOB_PAGE_TAB,
    SET_JOB_CANDIDATES
    } from '../actions';

const INIT_STATE = {
    activeTab: '',
    jobPageTab: 0,
    jobCandidatesData: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return { ...state, activeTab: action.payload };
        case SET_JOB_PAGE_TAB:
            return { ...state, jobPageTab: action.payload };
        case SET_JOB_CANDIDATES :
            return { ...state, jobCandidatesData: action.payload };
        default:
            return { ...state };
    }
};
