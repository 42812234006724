import { combineReducers }        from 'redux';
import settings                   from './settings/reducer';
import menu                       from './menu/reducer';
import authUser                   from './auth/reducer';
import spinnerReducer             from './spinner/reducer';
import activeTabReducer           from './JobView/reducer';
import candidateActiveTabReducer  from './OverviewCandidate/reducer';
import publicReducer              from './public/reducer';
import devWidth                   from "./deviceWidth/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  spinnerReducer,
  activeTabReducer,
  candidateActiveTabReducer,
  publicReducer,
  devWidth
});

export default reducers;
