import {
    CHANGE_LOCALE,
    SET_SETTINGS,
    SET_DEFAULT_LOGO,
    SET_IP_WHITELIST,
    SET_RESTRICTION,
    SET_VERSION,
    SET_GPDR,
    SET_GPDR_DAYS,
    SET_GDPR_SETTINGS
} from '../actions';

export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return {
        type: CHANGE_LOCALE,
        payload: locale,
    };
};

export const setSettings = (settings) => {
    return {
        type: SET_SETTINGS,
        payload: settings,
    };
};

export const setDefaultLogo = (logo) => {
    return {
        type: SET_DEFAULT_LOGO,
        payload: logo,
    };
};

export const setAllowedIPs = (allowedIPs) => {
    return {
        type: SET_IP_WHITELIST,
        payload: allowedIPs,
    };
};

export const setRestriction = (value) => {
    return {
        type: SET_RESTRICTION,
        payload: value,
    };
};

export const setVersion = (value) => {
    return {
        type: SET_VERSION,
        payload: value,
    };
};

export const toggleGDPR = (value) => {
    return {
        type: SET_GPDR,
        payload: value
    }
}

export const setGDPRExpiry = (days) => {
    return {
        type: SET_GPDR_DAYS,
        payload: days
    }
}

export const setGdprSettings = (gdprSettings) => {
    return {
        type: SET_GDPR_SETTINGS,
        payload: gdprSettings
    }
}
