import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    LOGGED_USER
} from '../actions';

const INIT_STATE = {
    user: null,
    loading: false,
    error: '',
    accessToken: localStorage.getItem('access_token'),
    refreshToken: localStorage.getItem('refresh_token')
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true, error: ''};
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                accessToken: action.payload['access_token'],
                refreshToken: action.payload['refresh_token'],
                error: ''
            };
        case LOGIN_USER_ERROR:
            return {...state, loading: false, user: '', error: action.payload.description};
        case REGISTER_USER:
            return {...state, loading: true, error: ''};
        case REGISTER_USER_SUCCESS:
            return {...state, loading: false, user: action.payload.uid, error: ''};
        case REGISTER_USER_ERROR:
            return {...state, loading: false, user: '', error: action.payload.message};
        case LOGOUT_USER:
            return {...state, user: null, error: ''};
        case LOGGED_USER:
            return {...state, user: action.payload, error: ''}
        default:
            return {...state};
    }
};
