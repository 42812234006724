import {
    SHOW_SPINNER_MODAL,
    HIDE_SPINNER_MODAL
} from '../actions'

const INIT_STATE = {
    visible: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_SPINNER_MODAL:
            return {...state, visible: true};
        case HIDE_SPINNER_MODAL:
            return {...state, visible: false};
        default:
            return {...state};
    }
};