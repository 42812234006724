/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SET_SETTINGS = 'GET_SETTINGS';
export const SET_DEFAULT_LOGO = 'SET_DEFAULT_LOGO';
export const SET_IP_WHITELIST = 'SET_IP_WHITELIST';
export const SET_RESTRICTION = 'SET_RESTRICION';
export const SET_VERSION = 'SET_VERSION';
export const SET_GPDR = 'SET_GPDR';
export const SET_GPDR_DAYS = 'SET_GDPR_DAYS';
export const SET_GDPR_SETTINGS = 'SET_GDPR_SETTINGS';

export const SET_DEVICE_WIDTH = 'SET_DEVICE_WIDTH';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export const LOGGED_USER = 'LOGGED_USER';
export const SHOW_SPINNER_MODAL = 'SHOW_SPINNER_MODAL';
export const HIDE_SPINNER_MODAL = 'HIDE_SPINNER_MODAL';

/* OVERVIEW CANDIDATE */
export const SET_CANDIDATE_ACTIVE_TAB = 'SET_CANDIDATE_ACTIVE_TAB';

/* JOB VIEW */
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_JOB_PAGE_TAB = 'SET_JOB_PAGE_TAB';
export const SET_JOB_CANDIDATES = 'SET_JOB_CANDIDATES';

/* PUBLIC */
export const PUBLIC_SET_JOB_DATA = 'PUBLIC_SET_JOB_DATA';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './spinner/actions';
export * from './public/actions';
export * from './JobView/actions';
export * from './deviceWidth/actions';
