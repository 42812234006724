/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 992;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English', direction: 'ltr' },
    /*{ id: "es", name: "Español - LTR", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },*/
    { id: 'bg', name: 'Български', direction: 'ltr' },
];

// Default number of rows, rendered in a table.
export const defaultGridSize = 10;

// Default layout - list or grid.
export const listLayout = 'list';
export const gridLayout = 'grid';

export const firebaseConfig = {
    apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
    authDomain: 'gogo-react-login.firebaseapp.com',
    databaseURL: 'https://gogo-react-login.firebaseio.com',
    projectId: 'gogo-react-login',
    storageBucket: 'gogo-react-login.appspot.com',
    messagingSenderId: '216495999563',
};

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = 'light.blue';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const recaptchaKey = '6LckyKIZAAAAAGhAcjD8qfOSpqbxSNX1gWCfJTJZ';

export const domain = window.location.origin + '/';
// export const domain = 'http://localhost:8480/';
// export const domain = 'https://evalrecruit.devzone.sirma.bg/';

export const prefix = 'api/v1/';
