import {
	PUBLIC_SET_JOB_DATA,
} from '../actions';

const INIT_STATE = {
	publicJobData: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case PUBLIC_SET_JOB_DATA:
    		return Object.assign({}, state, {
    			publicJobData: action.payload
    		})

		default: return { ...state };
	}
}
