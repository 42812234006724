import {
    SET_ACTIVE_TAB,
    SET_JOB_PAGE_TAB,
    SET_JOB_CANDIDATES
    } from '../actions';

export const setActiveTab = (activeTab) => {
    return {
        type: SET_ACTIVE_TAB,
        payload: activeTab,
    };
};

export const setJobPageTab = (jobPageTab) => {
    return {
        type: SET_JOB_PAGE_TAB,
        payload: jobPageTab,
    };
};

export const setJobCandidates = (jobCandidates) => {
    return {
        type: SET_JOB_CANDIDATES,
        payload: jobCandidates,
    };
};
