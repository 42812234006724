import {
    PUBLIC_SET_JOB_DATA,
} from '../actions';

export const publicSetJobData = (payload) => {
    
    return (
        {
            type: PUBLIC_SET_JOB_DATA,
            payload: payload
        }
    )
}
