import {
    SHOW_SPINNER_MODAL,
    HIDE_SPINNER_MODAL
} from '../actions'

export const showSpinnerModal = () => {
    return {
        type: SHOW_SPINNER_MODAL
    };
};

export const hideSpinnerModal = () => {
    return {
        type: HIDE_SPINNER_MODAL
    };
};