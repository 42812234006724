import { fork, takeEvery } from 'redux-saga/effects';
import { LOGOUT_USER } from '../actions';

function* logout({ payload }) {
    const { history } = payload;

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id_token');
    history.push('/user/logout');
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
    yield fork(watchLogoutUser);
}
