import { SET_DEVICE_WIDTH } from '../actions';

const INIT_STATE = {
    deviceWidth: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_DEVICE_WIDTH:
            return { ...state, deviceWidth: action.payload };
        default:
            return { ...state };
    }
};
