import { SET_CANDIDATE_ACTIVE_TAB } from '../actions';

const INIT_STATE = {
    candidateActiveTab: '',
};

export default (state = INIT_STATE, action) => {
    if (action.type === SET_CANDIDATE_ACTIVE_TAB) {
        return { ...state, candidateActiveTab: action.payload };
    } else {
        return { ...state };
    }
};
